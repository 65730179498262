<template>
  <div>
    <el-form ref="form" :model="form" label-width="250px">
    <!-- 第一步 -->
    <!-- <el-form> -->
        <div style="margin-right: -220px;">
        <el-form-item>
            <div class="txt">
            <h2 style="padding: 0px;margin: 0px;color: rgb(120, 120, 120);font-family: 'Microsoft YaHei', Arial, sans-serif;text-align: center;">
                <p class="MsoNormal" align="center" style="text-align: center;padding: 0px;margin: 0pt 0pt 5pt;
                    text-indent: 0pt;line-height: 30pt;">
                <span style="padding: 0px;margin: 0px;list-style: none;font-family: 黑体;letter-spacing: 0pt;font-size: 22pt;">
                    <font face="黑体" style="padding: 0px; margin: 0px">权</font></span>
                <span style="padding: 0px;margin: 0px;list-style: none;font-family: 黑体;letter-spacing: 0pt;font-size: 22pt;"
                    ><font face="黑体" style="padding: 0px; margin: 0px"
                    >利</font
                    ><font face="黑体" style="padding: 0px; margin: 0px"
                    >保</font
                    ><font face="黑体" style="padding: 0px; margin: 0px"
                    >证</font
                    ><font face="黑体" style="padding: 0px; margin: 0px"
                    >书</font
                    ></span
                >
                </p>
                <p class="MsoNormal"
                style="
                    text-align: left;
                    padding: 0px;
                    margin: 0pt 0pt 5pt;
                    text-indent: 0pt;
                    line-height: 30pt;
                "
                ><span
                    style="
                    padding: 0px;
                    margin: 0px;
                    list-style: none;
                    font-family: 宋体;
                    letter-spacing: 0pt;
                    font-size: 16pt;
                    "
                    ><font face="宋体" style="padding: 0px; margin: 0px;"
                    >本人（单位）保证所申请登记的如下作品（制品）的权利归本人（单位）所有，保证提交的文件真实、合法。如有不实，本人愿承担一切法律责任。</font
                    ></span
                >
                </p>
                <p
                class="p"
                style="
                    text-align: left;
                    padding: 0px;
                    margin: 0pt 0pt 5pt;
                    text-indent: 0pt;
                    line-height: 30pt;
                "
                >
                <span
                    style="
                    padding: 0px;
                    margin: 0px;
                    list-style: none;
                    font-family: 楷体_GB2312;
                    letter-spacing: 0pt;
                    font-size: 14pt;
                    "
                    ><font
                    face="楷体_GB2312"
                    style="padding: 0px; margin: 0px"
                    >说明：凡申请作品自愿登记者，均须签署如上权利保证书。如系剽窃、抄袭他人作品进行登记以及提交的文件不真实、不合法，除应承担相应的法律责任外，著作权行政管理部门还将视情况给予行政处罚。</font
                    ></span
                >
                </p>
            </h2>
            </div>
        </el-form-item>
        
        <el-form-item class="submitRow">
            <el-button type="primary" @click="next">同意并前往下一步</el-button>
        </el-form-item>
        </div>
    </el-form>
  </div>
</template>

<script>
import { queryExistOrderItems } from "@/api/EvidenceService";
import { queryObligeeList } from "@/api/ObligeeService";
import multipleSelect from "../../../../components/form/multipleSelect";
import choosePersonDialog from "../../../../components/form/choosePersonDialog";
import resultView from "../components/resultView";
import ChinaAreaData from "@/components/ChinaAreaData";
import { mapGetters } from "vuex";
import { mixinsScrollErrorPosition } from "../../../../mixins";
import constants from "@/utils/constants";

export default {
  name: "RegisterInputForm",
  mixins: [mixinsScrollErrorPosition],
  components: {
    multipleSelect,
    choosePersonDialog,
    resultView,
    ChinaAreaData,
  },
  props: {
    stepForm: Object,
  },
  data() {
    return {
      constants,
      step: 1,
      tabIdx: "tab1",
      // form: {
      //   paymode: 'wechat',
      // },
      form: this.stepForm,
      isAuthorDialogVisible: false,
      isRightDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo"]),
  },
  created() {
    //this.loadData();
  },
  methods: {
    loadData() {
      queryExistOrderItems().then(({ data }) => {
        this.fileList = data;
      });
      queryObligeeList(1, 100).then(({ data }) => {
        this.obligees = data.list;
      });
    },
    calAmount() {
      let amount = 0;
      let registerConfig = this.evidencePaymentPrice.register;
      amount = registerConfig.amount * 1;

      this.form.orderAmount = amount;
    },
    async onSubmit() {
      console.log("onSubmit..");
      try {
        const formBaseResult = await this.$refs["form"].validate();
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        this.mixinsScrollView();
        return;
      }
      this.setTabIdx("tab2");
    },
    setTabIdx(tabIdx) {
      this.tabIdx = tabIdx;
    },
    onSetAuthorDialogClose() {
      this.isAuthorDialogVisible = false;
    },
    onSetRightDialogClose() {
      this.isRightDialogVisible = false;
    },
    onChooseAuthor(users) {
      this.form.author = users;
    },
    onChooseRight(users) {
      this.form.right = users;
    },
    valiCity(rule, value, callback) {
      if (!this.form.province) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.city) {
        callback(new Error("请选择城市"));
        return;
      }
      if (!this.form.area) {
        callback(new Error("请选择区县"));
        return;
      }
      callback();
    },

    chooseSignatureType() {},

    next(){
        this.$emit('getnext',2,this.form)
    },


  },
};
</script>

<style lang='scss' scoped>
@import "../../../../assets/css/inputForm.scss";
.commonWhiteBg {
  min-height: 800px;
  /deep/ .ant-popover-arrow {
    left: 30%;
  }
}
.ownershipRow {
  margin-bottom: 0;
}
.ownershipWrap {
  position: relative;
  padding-top: 40px;
  /deep/ .el-radio-group {
    position: absolute;
    width: 200%;
    left: 0;
    top: 11px;
  }
  .tips {
    margin-top: 0;
    .red {
      color: #ff4d4f;
      vertical-align: middle;
    }
  }
}
// .tips {
//   margin-top: 20px;
// }
.tips {
  // position: initial;
  position: absolute;
    width: 384px;
    right: -410px;
    top: 0;
}
.signatureName {
  .el-select,
  .el-input {
    width: calc(110% / 2.4);
  }
  .el-select {
    margin-right: calc(20% / 2.4);
  }
}
.Uploader2 {
  font-size: 14px;
  height: 100%;
  text-align: center;
  background: #f9fafc;
  border-radius: 4px;
  border: 1px solid #c0ccda;
}
/deep/ .submitRow .el-form-item__content {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MainStepsForm .submitRow button {
  width: 46% !important;
}

.stepTitle{
  width: 100%;
  margin: 50px 0 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stepTitle li {
  padding: 0 10px;
}
.stepTitle li span{
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(0, 18, 22, 0.2);
  font-size: 16px;
  color: rgba(0, 18, 22, 0.2);

}
.stepTitle li p{
  color: rgba(0, 18, 22, 0.45);
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
}
.stepTitle li.active span{
  border-color: #007DFF;
  color: #fff;
  background: #007DFF;
}
.stepTitle li.active p{
  color: #007DFF;
}

// 适配移动端
@media screen and (max-width: 991px){
  /deep/ .el-form-item__content{margin-left: 0!important;display: inline-block;width: 100%;}
  /deep/ .el-form div{margin-right: 0!important;}
  .txt h2 p:first-child span{font-size: 20px!important;}
  .txt h2 p:nth-child(2) span{font-size: 18px!important;}
  .txt h2 p:nth-child(3) span{font-size: 14px!important;}
}

</style>
