<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="250px">

        <!-- 第四步 -->
        <div>
            <el-form-item label="创建作品名称" prop="subject">
            <el-input placeholder="请输入作品名称" v-model="form.subject"></el-input>
            <a-alert class="tips" type="warning" v-if="form.worksType == '1'">
                  <div slot="description" >
                    文字：小说、诗词、散文、论文等以文字形式表现的作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '2'">
                  <div slot="description" >
                    口述：即兴的演说、授课、法庭辩论等以口头语言形式表现的作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '3'">
                  <div slot="description" >
                    音乐：歌曲、交响乐等能够演唱或者演奏的带词或者不带词的作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '4'">
                  <div slot="description" >
                    戏剧：话剧、歌剧、地方戏等供舞台演出的作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '5'">
                  <div slot="description" >
                    曲艺：相声、快书、大鼓、评书等以说唱为主要形式表演的作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '6'">
                  <div slot="description" >
                    舞蹈：通过连续的动作、姿势、表情等表现思想情感的作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '7'">
                  <div slot="description" >
                    杂技：杂技、魔术、马戏等通过形体动作和技巧表现的作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '8'">
                  <div slot="description" >
                    美术：绘画、书法、雕塑等以线条、色彩或者其他方式构成的有审美意义的平面或者立体的造型艺术作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '9'">
                  <div slot="description" >
                    建筑：以建筑物或者构筑物形式表现的有审美意义的作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '10'">
                  <div slot="description" >
                    摄影：借助器械在感光材料或者其他介质上记录客观物体形象的艺术作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '11'">
                  <div slot="description" >
                    电影：是指摄制在一定介质上，由一系列有伴音或者无伴音的画面组成，并且借助适当装置放映或者以其他方式传播的作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '12'">
                  <div slot="description" >
                    类似摄制电影方法创作的作品：是指摄制在一定介质上，由一系列有伴音或者无伴音的画面组成，并且借助适当装置放映或者以其他方式传播的作品（电视剧，动画片，视频，录音、录像制品等都归于这一类）。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '13'">
                  <div slot="description" >
                    工程设计图、产品设计图：为施工、生产绘制的工程设计图、产品设计图，以及反映地理现象、说明事物原理或者结构的地图、示意图等作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '14'">
                  <div slot="description" >
                    地图、示意图：为施工、生产绘制的工程设计图、产品设计图，以及反映地理现象、说明事物原理或者结构的地图、示意图等作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '15'">
                  <div slot="description" >
                    模型：为展示、试验或者观测等用途，根据物体的形状和结构，按照一定比例制成的立体作品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '16'">
                  <div slot="description" >
                    录音制品：任何对表演的声音和其他声音的录制品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '17'">
                  <div slot="description" >
                    录像制品：电影作品和以类似摄制电影的方法创作的作品以外的任何有伴音或无伴音的连续相关形象、图像的制品。
                  </div>
            </a-alert>
            <a-alert class="tips" type="warning" v-if="form.worksType == '18'">
                  <div slot="description" >
                    其他：根据法律法规规定的其他作品。选择“其他”类时，请说明具体情况。
                  </div>
            </a-alert>
            </el-form-item>
            <el-form-item label="作品创作类型" prop="worksType">
            <el-select
                v-model="worksTypeText"
                placeholder="请选择创作类型"
                @change="selectWorksType"
            >
                <el-option
                v-for="(item, index) in constants.worksType"
                :key="index"
                :label="item.label"
                :value="index"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item
            label="上传作品"
            ref="uploader"
            class="uploadWraper"
            prop="files"
            >
            <el-upload
                class="commonUpload"
                drag
                :action="uploadUrl"
                :headers="uploadHeaders"
                :on-success="uploadSuccess"
                :on-remove="(file, fileList) => {handleRemove(file, fileList)}"
                :before-upload="uploadBefore"
                multiple
                :file-list="fileList"
            >
                <div class="commonUploadInner">
                <i class="iconfont iconUpload">&#xe606;</i>
                <div class="el-upload__text">
                    <div>将文件拖到此处，或<em>点击上传</em></div>
                    <div>支持扩展名：.rar .zip .doc .docx .pdf .jpg...</div>
                </div>
                </div>
            </el-upload>
            <a-alert class="tips" type="warning">
                <div slot="message">温馨提示：</div>
                <div slot="description">
<!--                 1、同一批的存证文件归属于同一标签，不支持重置；<br />
                2、支持文件批量上传，一次不能超过10个文件；<br />
                3、文件永久保全。<br />
                4、如需做版权登记，请在此处将证书及作品属性相关文件一并上传。<br />
                5、超过500M的文件，存证收费将会相应增加。 -->
                1、同一批的存证文件归属于同一标签，不支持重置；<br>
                2、默认格式:  JPG | jpg | jpeg | gif | bmp | png | doc | docx | xls | xlsx | ppt | pptx | pdf | txt | wav | mp4 | wmv | mp3 | psd <br>
                3、单文件大小: 不超过500M，一次最多上传10个， 作品名称、作品电子文件名称、作品电子文件内作品名称须保持一致。 <br>
                4、文件永久保全。 <br>
                </div>
            </a-alert>
            </el-form-item>
            <!-- <el-form-item label="办理时限" prop="timeLimit">
                <el-radio v-model="timeLimit" label="1">10个工作日(正常)</el-radio>
                <el-radio v-model="timeLimit" label="2">5个工作日(加急挡)</el-radio>
                <el-radio v-model="timeLimit" label="3">3个工作日(加急挡)</el-radio>
                <el-radio v-model="timeLimit" label="4">1个工作日(加急挡)</el-radio>
            </el-form-item> -->
            <el-form-item class="submitRow">
            <el-button type="primary" @click="prev">上一步</el-button>
            <el-button type="primary" @click="next">下一步</el-button>
            </el-form-item>
            
        </div>
    </el-form>

  </div>
</template>

<script>
import { queryExistOrderItems } from "@/api/EvidenceService";
import { queryObligeeList } from "@/api/ObligeeService";
import multipleSelect from "../../../../components/form/multipleSelect";
import choosePersonDialog from "../../../../components/form/choosePersonDialog";
import resultView from "../components/resultView";
import ChinaAreaData from "@/components/ChinaAreaData";
import { mapGetters } from "vuex";
import { mixinsScrollErrorPosition } from "../../../../mixins";
import constants from "@/utils/constants";


export default {
  name: "RegisterInputForm",
  mixins: [mixinsScrollErrorPosition],
  components: {
    multipleSelect,
    choosePersonDialog,
    resultView,
    ChinaAreaData,
  },
  props: {
    stepForm: Object,
  },
  data() {
    return {
      uploadUrl:`${process.env.VUE_APP_BASEURL}/v1/ins/file/upload?path=exist/evidence/files/`,
      uploadHeaders:{ 
        'Authorization': this.$store.getters.token,
        'Product-Mode': "READ",
        'Terminal-Code': "IPCHAIN2"+ this.$store.getters.terminalCode,
        'Terminal-Type': "WEB",
        'Merchant-Id': "5"
      },
      constants,
      step: 1,
      tabIdx: "tab1",
      worksTypeText:'',
      fileList2: [],
      fileList: [],
      timeLimit: "1",

      // form: {
      //   subject: "",
      //   worksType: "",
      //   files: [],

      // },
      form: this.stepForm,
      rules: {
        subject: [
          { required: true, message: "请输入登记名称", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        worksType: [
          { required: true, message: "请选择作品创作类型", trigger: "blur" },
        ],
        files: [
          { required: true, message: "请上传作品", trigger: "blur" },
        ],

        province: [
          { required: true, validator: this.valiCity, trigger: "blur" },
        ],
      },
      isAuthorDialogVisible: false,
      isRightDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo"]),
  },
  created() {
    // 从 localStorage 中加载数据
    const savedData = localStorage.getItem(`form_${this.mid}`);
    if (savedData) {
      this.form = JSON.parse(savedData);
    }
    
      // 此处不需要加载存证文件以及权利人信息
    //this.loadData();
    // this.form = this.stepForm
    if(this.form.worksType){
      this.worksTypeText = this.constants.worksType[this.form.worksType * 1 - 1].label;
    }
    if(this.form.files){
      let arr = []
      for(let i=0;i<this.form.files.length;i++){
        arr.push({
          name: this.form.files[i].sourceName,
          url: this.form.files[i].downloadUrl,
        })
      }
      this.fileList = arr
    }
    
  },
  watch: {
    form: {
      handler(newValue) {
        // 自动保存表单数据到 localStorage
        localStorage.setItem(`form_${this.mid}`, JSON.stringify(newValue));
      },
      deep: true
    }
  },
  methods: {
    loadData() {
      // queryExistOrderItems().then(({ data }) => {
      //   this.fileList = data;
      // });
      queryObligeeList(1, 100).then(({ data }) => {
        this.obligees = data.list;
      });
    },
    calAmount() {
        console.log("calAmount evidencePaymentPrice",this.evidencePaymentPrice)
      let amount = 0;
      let registerConfig = this.evidencePaymentPrice.register;
      amount = registerConfig.amount * 1;

      this.form.orderAmount = amount;
    },
    async onSubmit() {
      console.log("onSubmit..");
      try {
        const formBaseResult = await this.$refs["form"].validate();
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        this.mixinsScrollView();
        return;
      }
      this.setTabIdx("tab2");
    },
    setTabIdx(tabIdx) {
      this.tabIdx = tabIdx;
    },
    onSetAuthorDialogClose() {
      this.isAuthorDialogVisible = false;
    },
    onSetRightDialogClose() {
      this.isRightDialogVisible = false;
    },
    onChooseAuthor(users) {
      this.form.author = users;
    },
    onChooseRight(users) {
      this.form.right = users;
    },
    valiCity(rule, value, callback) {
      if (!this.form.province) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.city) {
        callback(new Error("请选择城市"));
        return;
      }
      if (!this.form.area) {
        callback(new Error("请选择区县"));
        return;
      }
      callback();
    },


    selectWorksType(index){
      this.worksTypeText = this.constants.worksType[index].label;
      this.form.worksType = this.constants.worksType[index].value;
      console.log(this.form.worksType);
    },

    uploadBefore(file) {
        const NoPassFileTypes=['.exe','.dmg','.js','.html','.json','.xml','.dtd','.jsp','.php','.asp','.aspx']
        const fileName=file.name
        const fileEnd=fileName.substring(fileName.indexOf("."))
				const isFileTypePass = !NoPassFileTypes.includes(fileEnd)
				const isLt500M = file.size / 1024 / 1024 < 500;

				if (!isFileTypePass) {
					this.$message.error('上传文件格式不能为'+fileEnd);
				}
				if (!isLt500M) {
					this.$message.error('上传文件大小不能超过 500MB!');
				}
				return isFileTypePass&&isLt500M;
			},
    uploadSuccess(response, file, fileList){
        console.log(arguments)
        this.$refs.uploader.clearValidate()
        this.form.files.push(response);
        this.calAmount();
    },
    handleRemove(file,fileList){
      let that=this;
      for(let i=0;i<this.form.files.length;i++){
        if(file.name == this.form.files[i].sourceName){
          // console.log("i-------",i);
          that.form.files.splice(i,1);
        }
      }
      // let response=file.response;
      // if(response){
      //   let index=that.form.files.findIndex(t=>{
      //     return t.name==file.name;
      //   });
      //   console.log("index-----",index);
      //   if(index>-1){
      //     that.form.files.splice(index,1);
      //   }
      // }
      // console.log(file,fileList);
      // 更新价格
      this.calAmount();
    },


    prev(){
        this.$emit('getnext',3)
    },
    async next(){
        try {
          const formBaseResult = await this.$refs["form"].validate();
        } catch (error) {
          // 有错误,定位到错误
          // eslint-disable-next-line
          console.error(error);
          this.mixinsScrollView();
          return;
        }
        this.$emit('getnext',5,this.form)
    },

  },
};
</script>

<style lang='scss' scoped>
@import "../../../../assets/css/inputForm.scss";
.commonWhiteBg {
  min-height: 800px;
  /deep/ .ant-popover-arrow {
    left: 30%;
  }
}
.ownershipRow {
  margin-bottom: 0;
}
.ownershipWrap {
  position: relative;
  padding-top: 40px;
  /deep/ .el-radio-group {
    position: absolute;
    width: 200%;
    left: 0;
    top: 11px;
  }
  .tips {
    margin-top: 0;
    .red {
      color: #ff4d4f;
      vertical-align: middle;
    }
  }
}
// .tips {
//   margin-top: 20px;
// }
.tips {
  // position: initial;
  position: absolute;
    width: 384px;
    right: -410px;
    top: 0;
}
.signatureName {
  .el-select,
  .el-input {
    width: calc(110% / 2.4);
  }
  .el-select {
    margin-right: calc(20% / 2.4);
  }
}
.Uploader2 {
  font-size: 14px;
  height: 100%;
  text-align: center;
  background: #f9fafc;
  border-radius: 4px;
  border: 1px solid #c0ccda;
}
/deep/ .submitRow .el-form-item__content {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MainStepsForm .submitRow button {
  width: 46% !important;
}

.stepTitle{
  width: 100%;
  margin: 50px 0 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stepTitle li {
  padding: 0 10px;
}
.stepTitle li span{
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(0, 18, 22, 0.2);
  font-size: 16px;
  color: rgba(0, 18, 22, 0.2);

}
.stepTitle li p{
  color: rgba(0, 18, 22, 0.45);
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
}
.stepTitle li.active span{
  border-color: #007DFF;
  color: #fff;
  background: #007DFF;
}
.stepTitle li.active p{
  color: #007DFF;
}

// 适配移动端
@media screen and (max-width: 991px){
  /deep/ .el-form-item__content{margin-left: 0!important;display: inline-block;width: 100%;}
  /deep/ .el-form-item__label{width: auto!important;}
  .tips{position: inherit;width: 100%;margin-top: 10px;right: 0;}
}

</style>
