<template>
  <div class="MainStepsForm">
    <div v-show="tabIdx == 'tab1'" class="container">
      <a-alert message="平台为你自行上传的作品进行版权存证，不对您上传内容的真实性进行验证" type="warning" show-icon />

      <!-- <steps :stepIdx="2"></steps> -->

      <div class="commonWhiteBg">
        <!-- <div class="ant-popover-arrow"></div> -->
        <div class="commonTitle">版权登记</div>
        <ul class="stepTitle">
          <li :class="{'active':step>=1}"><span>1</span><p>申请须知</p></li><img data-v-32a5759c="" src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/arrow.svg" class="arrow">
          <li :class="{'active':step>=2}"><span>2</span><p>著作权人信息</p></li><img data-v-32a5759c="" src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/arrow.svg" class="arrow">
          <li :class="{'active':step>=3}"><span>3</span><p>作品基本信息</p></li><img data-v-32a5759c="" src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/arrow.svg" class="arrow">
          <li :class="{'active':step>=4}"><span>4</span><p>上传作品</p></li><img data-v-32a5759c="" src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/arrow.svg" class="arrow">
          <li :class="{'active':step>=5}"><span>5</span><p>权利状况说明</p></li>
        </ul>
        <div class="formWrap">
          <Step1 v-if='step==1' @getnext='getnext' :stepForm="this.form"/>
          <Step2 v-if='step==2' @getnext='getnext' :stepForm="this.form"/>
          <Step3 v-if='step==3' @getnext='getnext' :stepForm="this.form"/>
          <Step4 v-if='step==4' @getnext='getnext' :stepForm="this.form"/>
          <Step5 v-if='step==5' @getnext='getnext' :stepForm="this.form"/>
        </div>
      </div>
    </div>

    <result-view v-if='step==6' @getnext='getnext' :orderInfo="form" @set-tab-idx="setTabIdx"></result-view>
    <!-- <result-view
      v-if="tabIdx == 'tab2'"
      :orderInfo="form"
      @set-tab-idx="setTabIdx"
    >
    </result-view> -->
  </div>
</template>

<script>
import { queryExistOrderItems } from "@/api/EvidenceService";
import { queryObligeeList } from "@/api/ObligeeService";
// import Steps from "../../components/Steps";
import multipleSelect from "../../../components/form/multipleSelect";
import choosePersonDialog from "../../../components/form/choosePersonDialog";
import resultView from "./components/resultView";
import ChinaAreaData from "@/components/ChinaAreaData";
import { mapGetters } from "vuex";
import { mixinsScrollErrorPosition } from "../../../mixins";
import constants from "@/utils/constants";

import Step1 from "./components/step1.vue";
import Step2 from "./components/step2.vue";
import Step3 from "./components/step3.vue";
import Step4 from "./components/step4.vue";
import Step5 from "./components/step5.vue";

export default {
  name: "RegisterInputForm",
  mixins: [mixinsScrollErrorPosition],
  components: {
    // Steps,
    multipleSelect,
    choosePersonDialog,
    resultView,
    ChinaAreaData,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  data() {
    return {
      constants,
      step: 1,
      tabIdx: "tab1",
      form: {
        paymode: 'wechat',
        right: [],
        creationNature: "1",
        creationPropertyDesc:{
          text:"",
        },
        finishTime: "",
        creationIntention:{
          text:"",
        },
        creationOriginality:{
          text:"",
        },
        province: "",
        city: "",
        publishStatus: "",
        province2: "",
        city2: "",
        publishTime: "",
        subject: "",
        worksType: "",
        files: [],
        ownership: "1",
        obtainType: "",
        authors: [
          {name:"",signatureType:"",signatureName:"",orders:1},
        ],
        obtainTypeDesc: {
          text:"",
        },
        ownershipPicture: {
          "additionalProp1": "",
          "additionalProp2": "",
          "additionalProp3": "",
          "additionalProp4": "",
          "additionalProp5": "",
          "additionalProp6": "",
          imgSourceName1: '', // 第5步上传之后显示图片名字
          imgSourceName2: '',
          imgSourceName3: '',
          imgSourceName4: '',
          imgSourceName5: '',
          imgSourceName6: '',
        },
        rightHave: [],
        extra: {
          processingTime: '15',
        },
      },
      rules: {
        subject: [
          { required: true, message: "请输入登记名称", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        province: [
          { required: true, validator: this.valiCity, trigger: "blur" },
        ],
      },
      isAuthorDialogVisible: false,
      isRightDialogVisible: false,

    };
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo"]),
  },
  created() {
    //this.loadData();
  },
  methods: {
    loadData() {
      queryExistOrderItems().then(({ data }) => {
        this.fileList = data;
      });
      queryObligeeList(1, 100).then(({ data }) => {
        this.obligees = data.list;
      });
    },
    calAmount() {
      let amount = 0;
      let registerConfig = this.evidencePaymentPrice.register;
      amount = registerConfig.amount * 1;

      this.form.orderAmount = (amount).toFixed(2);
    },
    async onSubmit() {
      console.log("onSubmit..");
      try {
        const formBaseResult = await this.$refs["form"].validate();
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        this.mixinsScrollView();
        return;
      }
      this.setTabIdx("tab2");
    },
    setTabIdx(tabIdx) {
      this.tabIdx = tabIdx;
    },
    onSetAuthorDialogClose() {
      this.isAuthorDialogVisible = false;
    },
    onSetRightDialogClose() {
      this.isRightDialogVisible = false;
    },
    onChooseAuthor(users) {
      this.form.author = users;
    },
    onChooseRight(users) {
      this.form.right = users;
    },
    valiCity(rule, value, callback) {
      if (!this.form.province) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.city) {
        callback(new Error("请选择城市"));
        return;
      }
      if (!this.form.area) {
        callback(new Error("请选择区县"));
        return;
      }
      callback();
    },
    selectArea(data) {
      this.form.province = data.selectData.province.name;
      this.form.city = data.selectData.city.name;
      this.form.area = data.selectData.area.name;
    },
    selectArea2(data) {
      this.form.province2 = data.selectData.province.name;
      this.form.city2 = data.selectData.city.name;
      this.form.area2 = data.selectData.area.name;
    },
    chooseFile() {
      let files = [];
      for (let i = 0; i < this.form.evidenceFile.length; i++) {
        const element = this.form.evidenceFile[i];
        files.push(this.fileList[element * 1]);
      }
      this.form.files = files;
      this.calAmount();
    },


    getnext(step, data) {
      // console.log("当前表单：",step, data);
      this.step = step;
      if(data) {
        this.form = Object.assign(this.form,data);
        console.log("所有表单：",this.form)
      }
      if(step == 6){
        this.tabIdx = "tab2"
      }
    }



  },
  // beforeRouteLeave(to, from, next) {
  //   this.$confirm("系统可能不会保存您所做的更改。", "离开此页面？", {
  //     confirmButtonText: "离开",
  //     cancelButtonText: "取消",
  //     type: "warning"
  //   })
  //     .then(() => {
  //       // confirmButton回调
  //       next()
  //     })
  //     .catch(() => {
  //       // cancelButton回调，把当前页路由推回
  //       // 不能使用this.$router进行路由操作，会触发beforeRouteLeave导致死循环
  //       window.history.pushState("", "", this.currentUrl)
  //     })
  // },
};
</script>

<style lang='scss' scoped>
@import "../../../assets/css/inputForm.scss";
.commonWhiteBg {
  min-height: 800px;
  /deep/ .ant-popover-arrow {
    left: 30%;
  }
}
.ownershipRow {
  margin-bottom: 0;
}
.ownershipWrap {
  position: relative;
  padding-top: 40px;
  /deep/ .el-radio-group {
    position: absolute;
    width: 200%;
    left: 0;
    top: 11px;
  }
  .tips {
    margin-top: 0;
    .red {
      color: #ff4d4f;
      vertical-align: middle;
    }
  }
}
// .tips {
//   margin-top: 20px;
// }
.tips {
  // position: initial;
  position: absolute;
    width: 384px;
    right: -410px;
    top: 0;
}
.signatureName {
  .el-select,
  .el-input {
    width: calc(110% / 2.4);
  }
  .el-select {
    margin-right: calc(20% / 2.4);
  }
}
.Uploader2 {
  font-size: 14px;
  height: 100%;
  text-align: center;
  background: #f9fafc;
  border-radius: 4px;
  border: 1px solid #c0ccda;
}
/deep/ .submitRow .el-form-item__content {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MainStepsForm .submitRow button {
  width: 46% !important;
}

.stepTitle{
  width: 100%;
  margin: 50px 0 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stepTitle li {
  padding: 0 10px;
}
.stepTitle li span{
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(0, 18, 22, 0.2);
  font-size: 16px;
  color: rgba(0, 18, 22, 0.2);

}
.stepTitle li p{
  color: rgba(0, 18, 22, 0.45);
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
}
.stepTitle li.active span{
  border-color: #007DFF;
  color: #fff;
  background: #007DFF;
}
.stepTitle li.active p{
  color: #007DFF;
}

// 适配移动端
@media screen and (max-width: 991px){
  .stepTitle{display: block;margin: 50px 0;}
  .stepTitle img{opacity: 0;}
}

</style>
